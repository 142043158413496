import React, { useState } from "react";

import "./App.css";
import {
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  TextField,
  CardHeader,
} from "@material-ui/core";
import softoxImage from "./images/Soft-Ox_logo_21.png";
import calculator from "./images/calculator.png";
function App() {
  const [yourstockAmount, setYourStockAmount] = useState("");
  const [stockAmount, setStockAmount] = useState(7751000);
  const [stockPrice, setStockPrice] = useState(112);
  const [pe, setpe] = useState(20);
  const [workingDays, setWorkingDays] = useState(260);
  const [tax, setTax] = useState(22);
  const [expenditures, setExpenditures] = useState(40000000);

  const [safeDesQuantity, setSafeDesQuantity] = useState(45000);
  const [safeDesEarning, setSafeDesEarning] = useState(20);

  const [effectDesQuantity, setEffectDesQuantity] = useState(0);
  const [effectDesEarning, setEffectDesEarning] = useState(20);

  const sharePercentage = yourstockAmount
    ? (yourstockAmount / stockAmount + Number.EPSILON) * 100
    : 0;

  const currentMarketCap = stockAmount * stockPrice;
  const earnings =
    ((safeDesEarning * safeDesQuantity + effectDesEarning * effectDesQuantity) *
      workingDays -
      expenditures) *
    (1 - tax / 100);
  const futureMarketCap = earnings * pe;

  const chunk = (str, n) => {
    var ret = [];
    var i;
    var len;

    for (i = 0, len = str.length; i < len; i += n) {
      ret.push(str.substr(i, n));
    }

    return ret;
  };

  const format = (str) => {
    return chunk(str.toString().split("").reverse().join(""), 3)
      .join(" ")
      .split("")
      .reverse();
  };

  return (
    <Container>
      <Grid container spacing={5}>
        <Grid container item xs={12} spacing={1}>
          <header className="App-header">
            <img src={softoxImage} className="softox-logo"></img>
            <img src={calculator} className="calculator"></img>
          </header>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Card className="basic-info" elevation={3}>
            <CardHeader title="Basic info"></CardHeader>
            <CardContent>
              <form className="form" noValidate autoComplete="off">
                <TextField
                  variant="outlined"
                  type="tel"
                  className="stock-posession"
                  label="Your stock posession"
                  value={yourstockAmount}
                  onChange={(e) => setYourStockAmount(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  type="tel"
                  label="Number of total stocks"
                  value="7751000"
                  value={stockAmount}
                  onChange={(e) => setStockAmount(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  type="tel"
                  label="Current stock price (NOK)"
                  value={stockPrice}
                  onChange={(e) => setStockPrice(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  type="tel"
                  label="Expected P/E"
                  value="20"
                  value={pe}
                  onChange={(e) => setpe(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  type="tel"
                  label="Working days pr. year"
                  value={workingDays}
                  onChange={(e) => setWorkingDays(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  type="tel"
                  label="Company Tax (percent)"
                  value={tax}
                  onChange={(e) => setTax(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  type="tel"
                  label="Expenditures /year (NOK)"
                  value={expenditures}
                  onChange={(e) => setExpenditures(e.target.value)}
                />
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item xs={12} md={6} spacing={1}>
          <Card elevation={3}>
            <CardHeader title="SafeDes earnings"></CardHeader>
            <CardContent>
              <form className="form" noValidate autoComplete="off">
                <TextField
                  variant="outlined"
                  type="tel"
                  label="Quantity sold pr. working day"
                  value={safeDesQuantity}
                  onChange={(e) => setSafeDesQuantity(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  type="tel"
                  label="Earnings pr. bottle (NOK)"
                  value={safeDesEarning}
                  onChange={(e) => setSafeDesEarning(e.target.value)}
                />
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item xs={12} md={6} spacing={1}>
          <Card elevation={3}>
            <CardHeader title="EffectDes earnings"></CardHeader>
            <CardContent>
              <form className="form" noValidate autoComplete="off">
                <TextField
                  variant="outlined"
                  type="tel"
                  label="Quantity sold pr. working day"
                  value={effectDesQuantity}
                  onChange={(e) => setEffectDesQuantity(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  type="tel"
                  label="Earnings pr. bottle (NOK)"
                  value={effectDesEarning}
                  onChange={(e) => setEffectDesEarning(e.target.value)}
                />
              </form>
            </CardContent>
          </Card>
        </Grid>

        <Grid container item xs={12} spacing={1}>
          <Card className="calculation" elevation={3}>
            <CardHeader title="Calculated information"></CardHeader>
            <CardContent>
              <Grid container item xs={12} spacing={1}>
                <Grid container item xs={12} md={6} spacing={1}>
                  <div>
                    <CardHeader
                      className="subtitle"
                      title="Softox current numbers"
                    ></CardHeader>
                    <div className="calculated-numbers">
                      <span className="number">{format(currentMarketCap)}</span>
                      <span className="label">Total Market Cap (NOK)</span>
                    </div>
                    <div className="calculated-numbers">
                      <span className="number">{format(stockPrice)}</span>
                      <span className="label">Stock price (NOK)</span>
                    </div>
                    <div className="calculated-numbers">
                      <span className="number">
                        {format(
                          Math.round(currentMarketCap * (sharePercentage / 100))
                        )}
                      </span>
                      <span className="label">Your share worth (NOK)</span>
                    </div>
                  </div>
                </Grid>
                <Grid container item xs={12} md={6} spacing={1}>
                  <div>
                    <CardHeader
                      className="subtitle"
                      title="Softox expected numbers"
                    ></CardHeader>
                    <div className="calculated-numbers">
                      <span className="number">{format(futureMarketCap)}</span>
                      <span className="label">Total Market Cap (NOK)</span>
                    </div>
                    <div className="calculated-numbers">
                      <span className="number">
                        {Math.round(futureMarketCap / stockAmount)}
                      </span>
                      <span className="label">Stock price (NOK)</span>
                    </div>
                    <div className="calculated-numbers">
                      <span className="number green">
                        {Math.round(
                          (futureMarketCap / currentMarketCap - 1) * 100
                        )}
                        %
                      </span>
                      <span className="label">Potential Growth</span>
                    </div>
                    <div className="calculated-numbers">
                      <span className="number">{format(earnings)}</span>
                      <span className="label">
                        Company earnings / year (after Tax,Expenditures) (NOK)
                      </span>
                    </div>
                    <div className="calculated-numbers">
                      <span className="number">
                        {format(
                          Math.round(futureMarketCap * (sharePercentage / 100))
                        )}
                      </span>
                      <span className="label">Your share worth (NOK)</span>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <hr></hr>
              <Grid container item xs={12} spacing={1}>
                <Grid container item xs={12} spacing={1}>
                  <div>
                    <CardHeader
                      className="subtitle"
                      title="Your numbers"
                    ></CardHeader>
                    <div className="calculated-numbers">
                      <span className="number">
                        {Math.round(sharePercentage * 100000) / 100000}%
                      </span>
                      <span className="label">Your share of Softox</span>
                    </div>
                    <div className="calculated-numbers">
                      <span className="number">
                        {Math.round(
                          (sharePercentage / 100) *
                            safeDesQuantity *
                            (workingDays / 12)
                        )}
                      </span>
                      <span className="label">
                        Your share of SafeDes bottles sold / month (Quantity)
                      </span>
                    </div>
                    <div className="calculated-numbers">
                      <span className="number">
                        {format(
                          Math.round(
                            (sharePercentage / 100) *
                              safeDesQuantity *
                              (workingDays / 12)
                          ) * safeDesEarning
                        )}
                      </span>
                      <span className="label">
                        Your share of income from SafeDes / month (NOK)
                      </span>
                    </div>
                    <div className="calculated-numbers">
                      <span className="number">
                        {Math.round(
                          (sharePercentage / 100) *
                            effectDesQuantity *
                            (workingDays / 12)
                        )}
                      </span>
                      <span className="label">
                        Your share of EffectDes bottles sold / month (Quantity)
                      </span>
                    </div>
                    <div className="calculated-numbers">
                      <span className="number">
                        {format(
                          Math.round(
                            (sharePercentage / 100) *
                              effectDesQuantity *
                              (workingDays / 12)
                          ) * effectDesEarning
                        )}
                      </span>
                      <span className="label">
                        Your share of income from EffectDes / month (NOK)
                      </span>
                    </div>

                    <div className="calculated-numbers">
                      <span className="number">
                        {format(Math.round((earnings * sharePercentage) / 100))}
                      </span>
                      <span className="label">
                        Your share of earnings / year (after Tax,Expenditures)
                        (NOK)
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div className="credit">Developed by Then AS</div>
    </Container>
  );
}

export default App;
